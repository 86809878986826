
import config from '../../config.json';

import GoogleMaps from 'google-maps-api';
let googleMaps = GoogleMaps(config.googleMapsApiKey)();

import geocoder from './geocoder';



// Coerce what was passed in, into a `LatLng` object
export default function(position) {
	return Promise.resolve(googleMaps).then((mapsApi) => {
		let result = position;

		if(typeof position === 'string') {
			result = geocoder(position).then((geocodingResults) => geocodingResults.position);
		}
		else if(typeof position === 'object' && !(position instanceof mapsApi.LatLng)) {
			result = new mapsApi.LatLng(position.lat || 0, position.lng || 0);
		}

		return result;
	});
}
