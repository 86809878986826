
// Format stylers utility: http://codepen.io/anon/pen/qOOMvN?editors=001
export default [
	/* =================== Administrative (e.g. cities) =================== */ {
		featureType: 'administrative',
		elementType: 'geometry',
		stylers: [
			{ saturation: -50 },
			{ visibility: 'off' }
		]

	}, {
		stylers: [
			{ visibility: 'on' },
			{ gamma: 1.33 }
		]

	}, {
		featureType: 'administrative.neighborhood',
		stylers: [
			{ lightness: 20 },
			{ visibility: 'on' },
			{ saturation: -80 }
		]

	}, {
		featureType: 'administrative.locality',
		stylers: [
			{ visibility: 'on' }
		]

	}, {
		featureType: 'administrative.province',
		stylers: [
			{ visibility: 'on' }
		]

	},

	{
		featureType: 'administrative',
		elementType: 'labels.text',
		stylers: [
			{ visibility: 'on' }
		]

	}, {
		featureType: 'administrative',
		elementType: 'labels.text.fill',
		stylers: [
			{ color: '#4f4c4d' }
		]

	},

	/* =================== Transit (e.g railroad tracks) =================== */ {
		featureType: 'transit.line',
		stylers: [
			{ color: '#4f4c4d' },
			{ lightness: 85 },
			{ saturation: -80 }
		]

	},

	{
		featureType: 'transit',
		elementType: 'labels.text',
		stylers: [
			{ visibility: 'on' }
		]

	}, {
		featureType: 'transit.station',
		elementType: 'labels.icon',
		stylers: [
			{ visibility: 'simplified' },
			{ saturation: -80 },
			{ lightness: 32 }
		]

	}, {
		featureType: 'transit',
		elementType: 'labels.text.fill',
		stylers: [
			{ color: '#4f4c4d' }
		]

	},

	/* =================== Roads =================== */ {
		featureType: 'road',
		stylers: [
			{ lightness: 50 },
			{ saturation: -50 }
		]

	},

	{
		featureType: 'road',
		elementType: 'labels.text',
		stylers: [
			{ visibility: 'on' }
		]

	}, {
		featureType: 'road',
		elementType: 'labels.text.fill',
		stylers: [
			{ visibility: 'on' },
			{ color: '#4f4c4d' }
		]

	},

	/* =================== Point of Intersts =================== */ {
		featureType: 'poi',
		stylers: [
			{ visibility: 'simplified' },
			{ lightness: 15 },
			{ saturation: -20 }
		]

	}, {
		featureType: 'poi.park',
		stylers: [
			{ visibility: 'simplified' },
			{ visibility: 'off' }
		]

	}, {
		featureType: 'poi.business',
		stylers: [
			{ visibility: 'simplified' }
		]

	}, {
		featureType: 'poi.medical',
		stylers: [
			{ visibility: 'simplified' }
		]

	},

	{
		featureType: 'poi',
		elementType: 'labels',
		stylers: [
			{ visibility: 'on' }
		]

	}, {
		featureType: 'poi',
		elementType: 'labels.text.fill',
		stylers: [
			{ color: '#4f4c4d' },
			{ visibility: 'off' }
		]

	},


	/* =================== Water =================== */ {
		featureType: 'water',
		stylers: [
			{ color: '#cee0e0' },
			{ lightness: 0 },
			{ saturation: 15 }
		]

	},

	{
		featureType: 'water',
		elementType: 'labels.text',
		stylers: [
			{ visibility: 'on' }
		]

	}, {
		featureType: 'water',
		elementType: 'labels',
		stylers: [
			{ visibility: 'on' }
		]

	},

	/* =================== Landscape =================== */ {
		featureType: 'landscape',
		stylers: [
			{ color: '#ffffff' }
		]

	},

	{
		featureType: 'landscape',
		elementType: 'labels.text',
		stylers: [
			{ visibility: 'on' }
		]

	}, {
		featureType: 'landscape',
		elementType: 'labels.text.fill',
		stylers: [
			{ color: '#4f4c4d' }
		]

	}
];


