import objectAssign from 'object-assign';

import config from '../../config.json';

import GoogleMaps from 'google-maps-api';
let googleMaps = GoogleMaps(config.googleMapsApiKey)();
import geocode from 'google-maps-api/geocode';

let localStorage = window.localStorage;

const defaults = {
	cachePrefix: 'geocoder-',
	disableCache: false
};

export default function(address, options) {
	let opts = objectAssign({}, defaults, options);

	let getCachedValue = Promise.resolve(localStorage.getItem(opts.cachePrefix + address))
		.then(JSON.parse);

	return getCachedValue
		.then((cachedValue) => {
			if(opts.disableCache) {
				throw new Error(`Disabling cache, grabbing new value`);
			}
			else if(!cachedValue) {
				throw new Error(`No value in cache for key: ${address}`);
			}

			return cachedValue;
		})
		// Fallback to generating a new response
		.catch(() => {
			//console.log('Making requests to geocoder api');
			return Promise.resolve(geocode({
				address: address
			}))
			.then((results) => {
				let result = {
					position: {
						lat: results[0].geometry.location.lat(),
						lng: results[0].geometry.location.lng()
					},
					results
				};
				localStorage.setItem(opts.cachePrefix + address, JSON.stringify(result));

				return result;
			});
		});
}
