import eventEmitter from 'event-emitter';
import qs from 'query-string';

import jQuery from 'jquery';

import geocoder from './lib/maps/geocoder';
import generateLocationMap from './lib/maps/generate-location-map';
import getFormattedAddressFromGeocodingResults from './lib/maps/get-formatted-address-from-geocoding-results';

import mapStyles from './map-styles';


let qsParams = qs.parse(window.location.search);
// The cache is used here for the geocoded lat/lng results
let shouldDisableCache = qsParams.core === 'reindex' || qsParams.core === 'forceReindex';


// Click, spacebar, or enter
let doesEventActivate = function(e) {
	return e.type === 'click' || (e.type === 'keydown' && (e.keyCode === 32 || e.keyCode === 13));
};

// This function just takes the elements on the page with the data attributes
// and creates some markers to go on the google maps embed
//
// `data-location-name`
// `data-address`
// `data-location-type`
// `data-location-learn-more-url`
let generateMarkerInfo = function(mapItems) {
	let itemToMarkerMap = new WeakMap();

	let getMarkersList = mapItems.map((locationItem) => {
		let name = locationItem.getAttribute('data-location-name');
		let addressString = locationItem.getAttribute('data-address');

		// Find the lat/lng of the address so we know where to place the marker
		return geocoder(addressString, {
				disableCache: shouldDisableCache
			})
			.then((geocodingResults) => {
				let position = geocodingResults.position;
				let addressInfo = getFormattedAddressFromGeocodingResults(geocodingResults.results[0].address_components);

				let locationType = locationItem.getAttribute('data-location-type');
				let learnMoreUrl = locationItem.getAttribute('data-location-learn-more-url');

				// The infoWindow is the tooltip that appears
				// when you click a marker to show more info
				let nameMarkup = name ? `<h3>${name}</h3>` : '';
				let learnMoreMarkup = learnMoreUrl ? `<p><a href="${learnMoreUrl}">Learn More</a><p>` : '';
				let infoWindowContent = `
					<div class="g-maps-info-window-popup-area">
						${nameMarkup}
						<p>${addressInfo.multilineFormattedAddress}</p>
						${learnMoreMarkup}
					</div>
				`;

				let marker = {
					markerPosition: position,
					markerClass: `g-maps-custom-${locationType}-marker`,
					infoWindowContent,
					popInfoWindowEmitter: eventEmitter()
				};

				return marker;
			})
			.then((marker) => {
				// Store it in the map
				itemToMarkerMap.set(locationItem, marker);

				return marker;
			})
			.catch((err) => {
				throw new Error(`Failed to geocode, item ${name}, with ${addressString}. Error: ${err.message}`);
			});
	});


	return Promise.all(getMarkersList).then(function(markers) {
		return {
			markers,
			itemToMarkerMap
		};
	});
	
	/// ----------------
	/*
	
	let getMarkers = Promise.settle(getMarkersList).then((results) => {
		return results.filter((r) => {
			return r.isFulfilled();
		});
	})
	.then((results) => {
		return results.map((r) => {
			return r.value();
		});
	});


	return getMarkers.then((markers) => {
		// We return the marker array as well as a
		// map that associates the DOM element with locationsMap marker instance.
		//
		// The map can be used to execute Marker events/methods when something happens to the element
		// Ex. Open the InfoWindow when the element in the list is clicked
		return {
			markers,
			itemToMarkerMap
		};
	});
	*/
};

// Returns a promise that resolves when all of the maps have been created and are ready
export default function(mapWrappers, mapItems) {
	
	return Promise.all($(mapWrappers).map((index, mapWrapper) => {
		let getMarkerInfo = generateMarkerInfo(mapItems);
		
		// Hook it up so if you click this location in the list on the side,
		// it will move to that place on the map
		getMarkerInfo.then((markerInfo) => {
			$(mapItems).on('click keydown', (e) => {
				// If click or spacebar, or enter is pressed
				if(doesEventActivate(e)) {
					let marker = markerInfo.itemToMarkerMap.get(e.target);
					if(marker) {
						marker.popInfoWindowEmitter.emit('open');
					}
				}
			});
		});
		
		// Make the map
		return getMarkerInfo.then((markerInfo) => {
			return generateLocationMap(mapWrapper, {
				mapStyles,
				markers: markerInfo.markers,
				maxZoomAfterFitBounds: 10
			});
		});
	}));
}

