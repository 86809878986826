import objectAssign from 'object-assign';
import jQuery from 'jquery';

import config from '../../config.json';

import GoogleMaps from 'google-maps-api';
let googleMaps = GoogleMaps(config.googleMapsApiKey)();

import geocoder from './geocoder';
import getPositionLatLng from './get-position-lat-lng';
import handleMarkers from './handle-markers';
import friendlyEmbedMouseCapture from './friendly-embed-mouse-capture';


const defaults = {
	// Accepted values
	//	 - LatLng: https://developers.google.com/maps/documentation/javascript/reference?hl=en#LatLng
	//	 - string address
	//	 - object { lat: 0, lng: 0 }
	position: undefined,
	// Array marker objects
	// See `markerDefaults` in `handle-markers`
	markers: [],
	// After all of the markers have been placed on the map,
	// we will fit the viewport bounds in order to have all markers in view
	//
	// This option will ensure that if you only have a few markers,
	// it won't be zoomed in too close
	maxZoomAfterFitBounds: 10,
	mapStyles: [],
	// Also see `friendly-embed-mouse-capture` options
	useFriendlyMouseCapture: true
};


let generateMap = function(mapElement, options) {
	let opts = objectAssign({}, defaults, options);
	
	return Promise.resolve(googleMaps).then((mapsApi) => {
		let mapDefaults = {
			zoom: 6,
			center: new mapsApi.LatLng(44.964765, -93.2003),

			mapTypeControl: false,
			panControl: false,
			scaleControl: false,
			streetViewControl: false,
			zoomControl: true,
			zoomControlOptions: {
				style: mapsApi.ZoomControlStyle.LARGE,
				position: mapsApi.ControlPosition.RIGHT_CENTER
			},

			styles: opts.mapStyles
		};

		// These are options that will get passed to the google maps API
		let mapOptions = objectAssign({}, mapDefaults, opts);

		// Promise that resolves with the google map
		let mapReady = Promise.resolve().then(() => {
			if(!mapElement) {
				throw new Error("Couldn\'t make map. No container provided.");
			}

			let map = new mapsApi.Map(mapElement, mapOptions);

			return map;
		});


		// Coerce what was passed in, into a `LatLng` object
		let getLatLng = getPositionLatLng(opts.position);

		let handlingMarkers = mapReady.then(
				(map) => handleMarkers(map, opts.markers, { mapsApi })
			);
		
		var fittedToBounds = false;
		
		let centeringOnMakers = Promise.all([mapReady, handlingMarkers]).then((values) => {
			let map = values[0];
			let bounds = values[1];
			
			// Now fit the map to the newly inclusive bounds
			if(!bounds.isEmpty()) {
				fittedToBounds = true;
				map.fitBounds(bounds);
			}
			// Once the bounds have updated
			// Make sure we didn't zoom in too far
			mapsApi.event.addListenerOnce(map, 'bounds_changed', function(e) {
				if(map.getZoom() > opts.maxZoomAfterFitBounds) {
					map.setZoom(opts.maxZoomAfterFitBounds);
				}
			});
		});
		
		// Set the deccoded address as the center
		// Only if we haven't already centered on the list of markers
		Promise.all([mapReady, getLatLng]).then((values) => {
			let map = values[0];
			let newCenter = values[1];
			
			if (!fittedToBounds) {
				map.setCenter(newCenter);
			}
		});
		
		// Use friendly mouse capture so you can scroll down the page without getting stuck in the embed
		mapReady.then(() => {
			if(opts.useFriendlyMouseCapture) {
				friendlyEmbedMouseCapture(mapElement, opts);
			}
		});


		return mapReady;
	});
};


export default generateMap;
