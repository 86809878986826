import objectAssign from 'object-assign';

import config from '../../config.json';

import GoogleMaps from 'google-maps-api';
let googleMaps = GoogleMaps(config.googleMapsApiKey)();

import getPositionLatLng from './get-position-lat-lng';
import getCustomMarkerType from './custom-marker.js';

let getMarkerInfoWindowContent = function(marker) {
	let content = marker.infoWindowContent;
	if (typeof marker.infoWindowContent === 'function') {
		content = marker.infoWindowContent();
	}

	return content;
};


const defaults = {
	mapsApi: Promise.resolve(googleMaps)
};

// Put all the markers on the map and get the center of them all
export default function handleMarkers(map, markers, options) {
	let opts = objectAssign({}, defaults, options);

	return Promise.resolve(opts.mapsApi).then((mapsApi) => {
		let coerceToGoogleLatLng = function(position) {
			let latlng = position;
			if(typeof latlng === 'object' && !(latlng instanceof mapsApi.LatLng)) {
				latlng = new mapsApi.LatLng(latlng.lat || 0, latlng.lng || 0);
			}

			return latlng;
		};



		const markerDefaults = {
			markerPosition: new mapsApi.LatLng(0, 0) || {
				lat: 0,
				lng: 0
			},
			markerLabel: '',
			markerClass: 'g-maps-custom-marker',

			// The toolip overlay little window that pops up when you click on a marker
			// Some HTML
			infoWindowContent: '' || function() { },
			// We position directly on the marker but you can offset it with this option
			infoWindowOffset: {
				x: 0,
				y: 0
			} || function(markerElement) { return { x: 0, y: 0 }; },
			// Pass in an event emitter and trigger/fire `open` events
			// when you want to open the appropriate info window
			// https://www.npmjs.com/package/event-emitter
			//
			// marker.popInfoWindowEmitter.emit('open');
			// // You can also pass a zoomLevel
			// marker.popInfoWindowEmitter.emit('open', 10);
			// // Or even disable zoom on click by passing false
			// marker.popInfoWindowEmitter.emit('open', false);
			popInfoWindowEmitter: undefined,

			// Event callback
			click: function() { }
		};


		return getCustomMarkerType(mapsApi).then((CustomMarker) => {
			let bounds = new mapsApi.LatLngBounds();

			let infoWindow = new mapsApi.InfoWindow({
				content: ''
			});

			// Pop the info window
			let popInfoWindow = function(customMarkerInstance, marker) {
				marker = objectAssign({}, markerDefaults, marker);

				// Assemble the content
				let content = getMarkerInfoWindowContent(marker);

				// Only show if there is content to show
				if(content) {
					// Swap out the contents
					infoWindow.setContent(content);

					let offset = marker.infoWindowOffset ? new mapsApi.Size(marker.infoWindowOffset.x, marker.infoWindowOffset.y) : new mapsApi.Size(0, 0);
					if(typeof marker.infoWindowOffset === 'function') {
						content = marker.infoWindowOffset(customMarkerInstance.getElement());
					}

					infoWindow.setOptions({
						pixelOffset: offset
					});
					infoWindow.open(map, customMarkerInstance);
				}
			};

			// Add the markers to the map
			let whenMarkersAddedToMap = Promise.all(markers.map((marker) => {
				let getMarkerPosition = getPositionLatLng(marker.markerPosition);

				return getMarkerPosition.then((markerPosition) => {
					// Extend the bounds to include each marker's position
					bounds.extend(markerPosition);


					// Add the marker to the map
					let customMarker = new CustomMarker(
						markerPosition,
						map,
						{
							text: marker.label,
							markerClass: marker.markerClass,
							// Hook it up so when you click a marker
							// It moves you in the list of locations to that place
							click: function() {
								// Scroll to the item in the location list
								if(typeof marker.click === 'function') {
									marker.click();
								}

								popInfoWindow(this, marker);
							}
						}
					);


					// Allow the user to trigger the the infoWindow to open
					if(marker.popInfoWindowEmitter && marker.popInfoWindowEmitter.on) {
						marker.popInfoWindowEmitter.on('open', (zoomLevel = 10) => {
							// Center on marker
							map.panTo(markerPosition);
							// Zoom in close if they are not already
							if(zoomLevel) {
								if(map.getZoom() < zoomLevel) {
									map.setZoom(zoomLevel);
								}
							}

							popInfoWindow(customMarker, marker);
						});
					}

					return marker;
				});
			}));


			return whenMarkersAddedToMap.then(() => {
				return bounds;
			});
		});
	});
}


