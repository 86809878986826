// getCustomMarkerType(mapsApi).then((CustomMarker) => { /*...*/ });
export default function(mapsApi) {

	let createMarkerElement = function(marker, opts = {}) {
		let element = document.createElement('div');

		let classes = [].concat(opts.markerClass);
		classes.forEach(function(className) {
			element.classList.add(className);
		});
		// Add this one by default
		element.classList.add('g-maps-custom-marker');

		if(opts.text) {
			element.innerHTML = opts.text;
		}

		mapsApi.event.addDomListener(element, 'click', function(e) {
			// Fire the callback
			if(opts.click) {
				opts.click.bind(marker)(e);
			}

			mapsApi.event.trigger(marker, 'click');
		});


		return element;
	};

	// Based off of: http://humaan.com/custom-html-markers-google-maps/
	let CustomMarker = function(latlng, map, options) {
		this.latlng = latlng;
		this.opts = options;
		this.element = null;
		this.setMap(map);
	};

	// Inherit from the google maps API `OverlayView`
	// https://developers.google.com/maps/documentation/javascript/reference?hl=en#OverlayView
	CustomMarker.prototype = new mapsApi.OverlayView();

	CustomMarker.prototype.getElement = function() {
		let element = this.element || (() => {
			let el = createMarkerElement(this, this.opts);
			this.element = el;

			// Add it to the map
			let panes = this.getPanes();
			if(panes) {
				panes.overlayImage.appendChild(el);
			}

			return el;
		})();

		return element;
	};

	CustomMarker.prototype.draw = function() {
		let element = this.getElement();

		let point = this.getProjection().fromLatLngToDivPixel(this.latlng);

		if (point) {
			element.style.position = 'absolute';
			element.style.left = point.x + 'px';
			element.style.top = point.y + 'px';
		}
	};

	CustomMarker.prototype.remove = function() {
		if (this.element) {
			let parent = this.element.parentNode;
			if(parent) {
				parent.removeChild(this.element);
			}
			this.element = null;
		}
	};

	CustomMarker.prototype.getPosition = function() {
		return this.latlng;
	};

	return Promise.resolve(CustomMarker);
}

