/*global Typekit*/

import 'babel/polyfill';

import './lib/svg-fixer';
import './lib/details-shim.js';
import './lib/element-classlist-shim.js';

import generateCarousel from './lib/generate-carousel';

import locationsMap from './locations-map';

import $ from './lib/dom-utility';
import * as domUtility from './lib/dom-utility';

import jQuery from 'jquery';
window.jQuery = jQuery;
window.$ = jQuery;

try {
	Typekit.load({
		async: true
	});
} catch(e) {
//	console.log('Something went wrong with Typekit', e);
}


let scrollThreshold = 32;
domUtility.on(window, 'scroll DOMMouseScroll', (/*e*/) => {
	let scrollY = window.pageYOffset;

	if(scrollY > scrollThreshold) {
		$('.primary-header').forEach((header) => {
			header.classList.add('is-page-scrolled');
		});
	} else {
		$('.primary-header').forEach((header) => {
			header.classList.remove('is-page-scrolled');
		});
	}
});





let frameMap = {};
let generateRandomString = function() {
	return Math.random().toString(36).slice(2);
};

$('iframe').forEach((frame) => {
	var frameId =  generateRandomString();

	frame.addEventListener('load', () => {
		frameMap[frameId] = frame;
		var postFrameId = {
			type: 'assign-id',
			id: frameId
		};
		frame.contentWindow.window.postMessage(postFrameId, '*');
	});
});

let listenToFrameMessages = window.attachEvent ? window.attachEvent.bind(window, 'onmessage') : window.addEventListener.bind(window, 'message');

listenToFrameMessages((e) => {
	// When the frame posts its' own height to us, update the parent to fit it
	if(e.data.type === 'posting-current-height') {
		frameMap[e.data.id].style.height = e.data.height;
	}
});

domUtility.forEach($('.js-carousel'), (caroselWrapper) => {
	generateCarousel(caroselWrapper, {
		deprecationWarningDuration: 1300
	});
});

// The overall locations map with all of the location markers `/locations`
locationsMap($('.js-locations-map-wrapper'), $('.js-locations-map-item'))
	.catch((err) => {
//		console.log(err.message);
//		console.log(err.stack);
	});


locationsMap($('.js-location-map-wrapper'), $('.js-location-map-wrapper'))
	.catch((err) => {
//		console.log(err.message);
//		console.log(err.stack);
	});
