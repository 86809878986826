
let arrayHas = function(arr, compareItem) {
	return arr.some((item) => {
		return compareItem === item;
	});
};

/*eslint-disable */
const componentKeyMap = {
	street_number: 'streetNumber',
	route: 'street',
	locality: 'city',
	administrative_area_level_1: 'state',
	postal_code: 'zip'
};
/*eslint-enable */

// This method will grok the geocoding result from the google maps API
// and give you some nice usable pieces: formatted strings, address piece map
export default function getFormattedAddressFromGeocodingResults(addressComponents) {
	let addressMap = {
		streetNumber: undefined,
		street: undefined,
		city: undefined,
		state: undefined,
		zip: undefined
	};

	// Coerce the components into a the nice map above
	addressComponents.forEach((component) => {
		Object.keys(componentKeyMap).some((key) => {
			if(arrayHas(component.types, key)) {
				addressMap[componentKeyMap[key]] = component.long_name;

				return true;
			}
		});
	});

	let line1 = `${addressMap.streetNumber} ${addressMap.street}`;
	let line2 = `${addressMap.city}, ${addressMap.state} ${addressMap.zip}`;

	return {
		formattedAddress: line1 + ', ' + line2,
		multilineFormattedAddress: line1 + '<br />' + line2,
		addressMap
	};
}
