import $ from 'jquery';
import 'slick-carousel';

import objectAssign from 'object-assign';

let defaults = {
	arrows: false,
	centerMode: true,
	slidesToShow: 1,
	variableWidth: true,

	autoplay: true,
	autoplaySpeed: 4000,

	// non-slick option
	deprecationWarningDuration: 1000
};

export default function(element, options) {
	let opts = objectAssign({}, defaults, options);


	$(element).on('afterChange', function(e, slick, currentSlide) {
		//let $previousSlide = $(slick.$slides.get(currentSlide - 1));
		//$previousSlide.removeClass('is-deprecated');
	});

	$(element).on('beforeChange', function(e, slick, currentSlide, nextSlide) {
		let $currentSlide = $(slick.$slides.get(currentSlide));
		let $nextSlide = $(slick.$slides.get(nextSlide));

		$nextSlide.removeClass('is-deprecated');
		$currentSlide.addClass('is-deprecated');
	});


	$(element).slick(opts);

}
