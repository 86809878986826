import objectAssign from 'object-assign';

import $ from '../dom-utility';
import * as domUtility from '../dom-utility';

let distance = function(p1, p2) {
	return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
};

let isPointWithinDomRect = function(point, rect) {
	if(
		point.x > rect.left && point.x < rect.left + rect.width &&
		point.y > rect.top && point.y < rect.top + rect.height
	) {
		return true;
	}

	return false;
};

let convertEventToClientPoint = function(e) {
	return {
		x: e.clientX,
		y: e.clientY
	};
};


let defaults = {
	deadzoneThreshold: 20
};

// Disable google maps from capturing mouse events if we are just scrolling past it.
//
// A cursor conscious and friendly way to have unobtrusive map embeds
export default function friendlyEmbedMouseCapture(containers, options) {
	let opts = objectAssign({}, defaults, options);

	// Only toggle is state is different
	let toggleMapPointerEvents = function(container, state) {
		container.style.pointerEvents = state ? 'auto' : 'none';
	};


	let prevUnlockMousePosition = {
		x: 0,
		y: 0
	};
	// Once they move their mouse a certain threshold amount of distance,
	// enable the map interactivity
	domUtility.on(window, 'mousemove', function(e) {
		//console.log(e);
		let currentPosition = convertEventToClientPoint(e);
		let currentDistance = distance(prevUnlockMousePosition, currentPosition);

		let didMoveOutofDeadZone = currentDistance > opts.deadzoneThreshold;

		if(didMoveOutofDeadZone) {
			$(containers).forEach((container) => {
				toggleMapPointerEvents(container, true);
			});

			prevUnlockMousePosition = convertEventToClientPoint(e);
		}
	});
	// If they click directly on the map, give them access back
	domUtility.on(document, 'click', (e) => {
		let clickPosition = convertEventToClientPoint(e);

		$(containers).forEach((container) => {
			if(isPointWithinDomRect(clickPosition, container.getBoundingClientRect())) {
				toggleMapPointerEvents(container, true);
			}
		});
	});

	// Disable map interactivity if they are scroll on the page,
	// meaning they probably want to scroll past the map some of the time
	domUtility.on(window, 'scroll DOMMouseScroll', (e) => {
		$(containers).forEach((container) => {
			toggleMapPointerEvents(container, false);
		});
	});

}



